import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { Footer } from 'components/footer/Footer'
import { TopNavigationLandingPage } from 'components/header/topNavigationLandingPage/TopNavigationLandingPage'
import { Icon } from 'components/icon'
import { Layout } from 'components/layout/Layout'
import { PageTitle } from 'components/PageTitle'
import { Picture } from 'components/picture/Picture'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import { Caption, Title, Title_h4_default } from 'components/Typography'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { getIntlProps } from 'utils/i18n/getIntlProps'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

export default function Page() {
  const router = useRouter()
  return (
    <Layout
      header={<TopNavigationLandingPage variant="general-info" />}
      footer={<Footer variant="estate-planning" />}
    >
      <PageTitle title="Page not found" />

      <Spacer size={[0, 4]} />

      <Block>
        <Stack
          orientation="horizontal"
          css={{ justifyContent: 'space-between', alignItems: 'center' }}
          gap={'2rem'}
        >
          <LeftContent onGoBack={router.back} />

          <div className="desktop-only">
            <RightContent />
          </div>
        </Stack>
      </Block>
    </Layout>
  )
}

const LeftContent: FC<React.PropsWithChildren<{ onGoBack: () => void }>> = (props) => {
  const { t } = useTranslation('common')
  return (
    <Stack
      accurateSpacers
      gap={['1.5rem', '0.5rem']}
      css={[
        { maxWidth: pxToRemUnit(443) },
        BREAKPOINTS.MD.css({
          textAlign: 'center',
          alignItems: 'center',
          maxWidth: 'none',
        }),
      ]}
    >
      <Caption>{t('common.404.caption')}</Caption>
      <Title variant={Title_h4_default}>{t('common.404.title')}</Title>
      <div className="mobile-only">
        <Picture imageKey="illustrations/goodtrust-network" width="100%" />
      </div>
      <Spacer size={pxToRemUnit(48)} />
      <Button
        type="button"
        size="large"
        css={{ width: 'fit-content' }}
        leftIcon={<Icon name="ArrowLeft" />}
        onClick={props.onGoBack}
      >
        {t('common.404.go_back_button')}
      </Button>
    </Stack>
  )
}

const RightContent = () => {
  return <Picture imageKey="illustrations/goodtrust-network" width={520} />
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  return { props: { ...(await getIntlProps(ctx)) } }
}
